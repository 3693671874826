<template>
  <div :style="{ height: this.$store.state.availableTableHeight }">
    <incident-viewer :incidentId="incidentId" />
  </div>
</template>

<script>
import IncidentViewer from "@/components/incident/IncidentViewer.vue";

export default {
  components: {
    "incident-viewer": IncidentViewer
  },
  computed: {
    incidentId() {
      return Number(this.$route.params.incidentId);
    }
  }
};
</script>
