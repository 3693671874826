<style scoped>
.gray-container {
  background-color: #eceeef;
  border-radius: 5px;
}
</style>

<template>
  <b-container class="gray-container p-3">
    <b-row>
      <b-col>{{ $t("incident.title") }}</b-col>
      <b-col>{{ incident.title }}</b-col>
    </b-row>
    <b-row>
      <b-col>{{ $t("incident.vehicle-registration-number") }}</b-col>
      <b-col>{{ incident.vehicle.registrationNumber }}</b-col>
    </b-row>
    <b-row>
      <b-col>{{ $t("incident.reported-at") }}</b-col>
      <b-col> {{ incident.reportedAt != "" ? $d(new Date(incident.reportedAt), "long", $i18n.locale) : "" }}</b-col>
    </b-row>
    <b-row>
      <b-col>{{ $t("incident.reported-by") }}</b-col>
      <b-col>
        {{ incident.reportedBy.userName }}
        <phone-number :phoneNumber="incident.reportedBy.phone"></phone-number>
      </b-col>
    </b-row>
    <b-row>
      <b-col>{{ $t("incident.type") }}</b-col>
      <b-col>{{ typeTitle }}</b-col>
    </b-row>
    <b-row>
      <b-col>{{ $t("incident.status") }}</b-col>
      <b-col>{{ $t(`incident-status.${incident.status}`) }}</b-col>
    </b-row>
    <b-row v-if="incident.responsible != null">
      <b-col>{{ $t("incident.responsible-user") }}</b-col>
      <b-col>{{ incident.responsibleUser.userName }}</b-col>
    </b-row>
    <b-row>
      <b-col>{{ $t("incident.description") }}</b-col>
      <b-col>{{ incident.description }}</b-col>
    </b-row>
    <b-row>
      <b-col>{{ $t("incident.resolution") }}</b-col>
      <b-col>{{ incident.resolution }}</b-col>
    </b-row>
    <b-row>
      <b-col>{{ $t("incident.closed-at") }}</b-col>
      <b-col>{{ incident.modifiedAt != "" ? $d(new Date(incident.modifiedAt), "long", $i18n.locale) : "" }}</b-col>
    </b-row>
    <b-row>
      <b-col>{{ $t("incident.closed-by") }}</b-col>
      <b-col>
        {{ incident.modifiedBy.userName }}
        <phone-number :phoneNumber="incident.modifiedBy.phone"></phone-number>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <attachment-viewer :incidentId="incident.id" :files="incident.files" />
      </b-col>
    </b-row>
    <b-button @click="onEditIncident" v-if="editButton">{{ $t("incident.edit") }}</b-button>
    <edit-incident-modal @close="loadIncidentById" />
  </b-container>
</template>

<script>
import AttachmentViewer from "./attachment/AttachmentViewer.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import { mapActions } from "vuex";
import EditIncidentModal from "@/components/incident/EditIncidentModal.vue";
import auth from "@/services/auth.js";
import { OPEN_EDIT_INCIDENT_MODAL } from "@/const/events.js";
import { CUSTOM_INCIDENT_TYPE } from "@/const/incident-types.js";
import { CLOSED } from "@/const/incident-statuses.js";
import { DRIVER } from "@/const/user-role.js";

export default {
  components: {
    AttachmentViewer,
    PhoneNumber,
    EditIncidentModal
  },
  data() {
    return {
      incident: {
        id: NaN,
        title: "",
        vehicle: {
          registrationNumber: ""
        },
        reportedAt: "",
        reportedBy: {
          userName: "",
          phone: ""
        },
        type: "",
        status: "",
        description: "",
        resolution: "",
        responsibleUser: {
          userName: "",
          phone: ""
        },
        modifiedAt: "",
        modifiedBy: {
          userName: "",
          phone: ""
        },
        files: []
      }
    };
  },
  props: {
    incidentId: Number
  },
  computed: {
    editButton() {
      return this.incident.status !== CLOSED;
    },
    issueCategories() {
      return this.$store.state.issueCategories.items;
    },
    typeTitle() {
      if (this.incident.type) {
        if (this.incident.type == CUSTOM_INCIDENT_TYPE) {
          let issueCategory = this.issueCategories.find(x => x.id == this.incident.issueCategoryId);
          if (issueCategory) return issueCategory.title[this.$i18n.locale];
        } else {
          return this.$t(`incident-type.${this.incident.type}`);
        }
      }
      return "";
    }
  },
  methods: {
    ...mapActions(["incidents/get"]),
    onEditIncident() {
      if (auth.userRole() === DRIVER) {
        this.$bus.emit(OPEN_EDIT_INCIDENT_MODAL, this.incident);
      }
    },
    loadIncidentById() {
      this["incidents/get"](this.incidentId).then(resp => (this.incident = resp.data));
    }
  },
  mounted() {
    this.$store.dispatch("issueCategories/load");
    this.loadIncidentById();
  }
};
</script>
